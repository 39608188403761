"use client";

import React from "react";
import { CustomTypographyComponent } from "@cbex/ui/text";
import { Paper, Stack, Button } from "@mui/material";
interface FallbackErrorComponentProps {
  error: any;
  resetErrorBoundary: any;
}

export const FallbackErrorComponent = ({
  error,
  resetErrorBoundary,
}: FallbackErrorComponentProps) => (
  <Stack
    id="FallbackErrorComponent"
    spacing={2}
    alignItems="center"
    width="80%"
    padding={2}
  >
    <Paper>
      <Stack spacing={2} alignItems="center" width="100%" padding={2}>
        <CustomTypographyComponent>
          Something went wrong:
        </CustomTypographyComponent>
        <CustomTypographyComponent>{error.message}</CustomTypographyComponent>

        <Button variant="contained" onClick={resetErrorBoundary}>
          Try again
        </Button>
      </Stack>
    </Paper>
  </Stack>
);

export default FallbackErrorComponent;
